import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import AppContext from '../../context/app'
import * as Sentry from '@sentry/browser';
import moment from 'moment'
import threatAssessmentService from '../../services/threatAssessment';
import {Paper, TextField} from '@mui/material' 
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DataGridPro } from '@mui/x-data-grid-pro';
// import { PieChart, LineChart } from 'react-charts-d3';

import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart } from '@mui/x-charts/PieChart';

const colorScale = { from: 'black', to: '#a90004' }

const columns = [
    {
        field: "id",
        headerName: "Details",
        width: 150,
        renderCell: (params) => {
            return (<a target="_blank" href={`/assessment/detail/${params.id}`}><div className="threatAssessmentDetailsLink">Open</div></a>)
        },
        filterable: false,
        sortable: false
    },
    {
        field: 'assessedFirstName',
        headerName: 'First Name',
        width: 150,
    },
    {
        field: 'assessedLastName',
        headerName: 'Last Name',
        width: 150,
    },
    {
        field: 'institutionName',
        headerName: 'Organization',
        width: 160,
    }, 
    {
        field: 'buildingName',
        headerName: 'Building',
        width: 160,
    },
    {
        field: 'state',
        headerName: 'State',
        width: 100,
    }, 
    {
        field: 'typeName',
        headerName: 'Type',
        width: 150,
    }, 
    {
        field: 'resolutionClassification',
        headerName: 'Classification',
        width: 250,
    },
    {
        field: 'createDate',
        headerName: 'Created',
        type: 'date',
        width: 150,
        filterable: false
    },
    {
        field: 'age',
        headerName: 'Age',
        type: 'number',
        width: 120,
    }, 
    {
        field: 'grade',
        headerName: 'Grade',
        type: 'number',
        width: 120,
    }, 
    {
        field: 'gender',
        headerName: 'Gender',
        width: 120,
    },
    {
        field: 'subjectId',
        headerName: 'Subject ID',
        width: 120,
    },
];

export default function Analysis() {
    const context = useContext(AppContext)

    const [startDate, setStartDate] = useState(moment().subtract(3, 'month'))
    const [endDate, setEndDate] = useState(moment().add(1,'day'))
    const [searchWords, setSearchWords] = useState('')
    const [assessments, _setAssessments] = useState([]);
    const [filteredAssessments, setFilteredAssessments] = useState([])
    const [pieChartData, setPieChartData] = useState([])
    const [pieTypeChartData, setPieTypeChartData] = useState([])
    const [pieAgeChartData, setPieAgeChartData] = useState([])
    const [pieGenderChartData, setPieGenderChartData] = useState([])
    const [timelineChartData, setTimelineChartData] = useState()

    //goes away when moving to server search
    const searchAssessments = (assesssmentsToSearch) => {
        if (!searchWords || !searchWords.length) 
            return assesssmentsToSearch
        return assesssmentsToSearch.filter( a => a.wordSearch.indexOf(searchWords.toLowerCase()) > -1)
    }

    const setAssessments = (assessments) => {
        assessments.forEach((assessment) =>{
            assessment.id = assessment.threatAssessmentId
            assessment.resolutionClassification = assessment.resolutionClassification || assessment.threatLevelClassification
            assessment.createDate = moment.utc(assessment.createDate).local().toDate()

            //goes away when moving to server search
            assessment.wordSearch = `${assessment.assessedFirstName} ${assessment.assessedLirstName} ${assessment.comments} ${assessment.resolutionComment}`
            if (assessment.answers) {
                assessment.answers.forEach( answer => {
                    assessment.wordSearch += ` ${answer.questionText}`
                    if(answer.answerComment)
                        assessment.wordSearch += ` ${answer.answerComment}`
                })
            }
            assessment.wordSearch = assessment.wordSearch.toLowerCase()
        })
        assessments = searchAssessments(assessments)
        _setAssessments(assessments);
    };

    const updateCharts = (filters) => {
        let chartAssessments = assessments
        if (!filters.items.length)
            chartAssessments = assessments
        else {
            filters.items.forEach( filterItem => {
                switch (filterItem.operatorValue) {
                    case 'contains': {
                        if (!filterItem.value)
                            break;
                        chartAssessments = chartAssessments.filter( (fa) => fa[filterItem.columnField] && fa[filterItem.columnField].indexOf(filterItem.value) > -1 )
                        break;
                    }
                    case 'equals': {
                        if (!filterItem.value)
                            break;

                        chartAssessments = chartAssessments.filter( (fa) => fa[filterItem.columnField] === filterItem.value )
                        break;
                    }
                    case 'startsWith': {
                        if (!filterItem.value)
                            break;

                        chartAssessments = chartAssessments.filter( (fa) => fa[filterItem.columnField] && fa[filterItem.columnField].startsWith(filterItem.value) )
                        break;
                    }
                    case 'endsWith': {
                        if (!filterItem.value)
                            break;

                        chartAssessments = chartAssessments.filter( (fa) => fa[filterItem.columnField] && fa[filterItem.columnField].endsWith(filterItem.value) > -1 )
                        break;
                    }
                    case 'isEmpty': {
                        chartAssessments = chartAssessments.filter( (fa) => !!fa[filterItem.columnField] )
                        break;
                    }
                    case 'isNotEmpty': {
                        chartAssessments = chartAssessments.filter( (fa) => !fa[filterItem.columnField] )
                        break;
                    }
                    case '=': {
                        chartAssessments = chartAssessments.filter( (fa) => fa[filterItem.columnField] === +filterItem.value )
                        break;
                    }
                    case '!=': {
                        chartAssessments = chartAssessments.filter( (fa) => fa[filterItem.columnField] !== +filterItem.value )
                        break;
                    }
                    case '<': {
                        chartAssessments = chartAssessments.filter( (fa) => fa[filterItem.columnField] < +filterItem.value )
                        break;
                    }
                    case '<=': {
                        chartAssessments = chartAssessments.filter( (fa) => fa[filterItem.columnField] <= +filterItem.value )
                        break;
                    }
                    case '>': {
                        chartAssessments = chartAssessments.filter( (fa) => fa[filterItem.columnField] > +filterItem.value )
                        break;
                    }
                    case '>=': {
                        chartAssessments = chartAssessments.filter( (fa) => fa[filterItem.columnField] >= +filterItem.value )
                        break;
                    }
                }
            })
        }      
        setFilteredAssessments(chartAssessments)
    }

    const loadAssessments = async ()=> {
        try {
            if (startDate.isValid() && endDate.isValid()){
                let assessments = await new threatAssessmentService().search({startDate, endDate})
                setAssessments(assessments)
            }
        }
        catch(err){
            console.log(err)
            context.toastError('Loading')
            Sentry.captureException(err);
        }
    }

    const downnloadSearch = () => {
        new threatAssessmentService().downloadSearch({startDate, endDate})
    }

    useEffect( () => {
        window.scroll(0,0)
        loadAssessments()
    },[])

    useEffect( () => {
        if (startDate.isValid() && endDate.isValid())
            loadAssessments()
    },[startDate, endDate])

    useEffect( () => {
        assessments.filter( a => a.wordSearch.indexOf(searchWords.toLowerCase()) > -1)
        setFilteredAssessments(assessments)
    },[assessments])

    const debouncedSearchUpdated = useDebouncedCallback(
        () => {
            if (!searchWords && !searchWords.length)
                loadAssessments()
            else
                setAssessments(searchAssessments(assessments))
        },
        300
    );
    useEffect( () => {
        debouncedSearchUpdated()
    },[searchWords])


    useEffect( () => {
        if (filteredAssessments.length){
            const counts = filteredAssessments.reduce((acc, { resolutionClassification }) => {
                if (resolutionClassification && resolutionClassification !== 'NA') {
                    acc[resolutionClassification] = (acc[resolutionClassification] || 0) + 1;
                }
                return acc;
            }, {});
            const pieChartData = Object.entries(counts).map(([label, value]) => ({ label, value }));
            setPieChartData(pieChartData);

           
            // Group and count assessments by type
            const typeCounts = filteredAssessments.reduce((acc, assessment) => {
                acc[assessment.typeName] = (acc[assessment.typeName] || 0) + 1;
                return acc;
            }, {});

            // Transform counts into chart data
            const pieChartTypeData = Object.entries(typeCounts).map(([typeName, count]) => ({
                label: typeName,
                value: count,
            }));

            // Update the state with the new chart data
            setPieTypeChartData(pieChartTypeData);

          // Group and count assessments by age
          const ageCounts = filteredAssessments.filter(fa => !!fa.age).reduce((acc, assessment) => {
                acc[assessment.age] = (acc[assessment.age] || 0) + 1;
                return acc;
            }, {});

            // Transform counts into chart data
            const pieChartAgeData = Object.entries(ageCounts).map(([age, count]) => ({
                label: `${age} Years Old`,
                value: count,
            }));

            // Update the state with the new chart data
            setPieAgeChartData(pieChartAgeData);

           // Group and count assessments by gender
           const genderCounts = filteredAssessments.filter(fa => !!fa.gender).reduce((acc, assessment) => {
                 acc[assessment.gender] = (acc[assessment.gender] || 0) + 1;
                 return acc;
             }, {});
              // Transform counts into chart data
             const pieChartGenderData = Object.entries(genderCounts).map(([gender, count]) => ({
                 label: gender,
                 value: count,
             }));
 
             // Update the state with the new chart data
             setPieGenderChartData(pieChartGenderData);

            //get first date and array till now
            let months = []
            const dateStart =  moment.utc(filteredAssessments[filteredAssessments.length-1].createDate)
            const dateEnd = moment.utc(filteredAssessments[0].createDate)
            while (dateEnd.diff(dateStart, 'months') >= 0) {
                months.push(dateStart.format('M/YY'))
                dateStart.add(1, 'month')
            }

            const timeLineCounts = filteredAssessments.reduce((p, n) => {
                var timeStamp = moment.utc(n.createDate).local().format('M/YY')
                if (!p.hasOwnProperty(timeStamp))
                    p[timeStamp] = 0
                p[timeStamp]++
                return p;
            }, {})
            const values = months.map(m => timeLineCounts[m] || 0)
            setTimelineChartData({ xAxis: months, data: values })
        }
    },[filteredAssessments]) 


  return (
    <div id='assessment-analyze'>
         <Paper elevation={1}>
            <div className="flex-row">
                <div className="flex-col">
                    <span className="excel" onClick={downnloadSearch}>
                        <i className="fa fa-table"></i>
                    </span>
                </div>
            </div> 
            <div className="flex-row filter-bar">
                <div className="flex-col">
                    <TextField className="search-input" type="text" label="Search" value={searchWords} onChange={(e) => setSearchWords(e.target.value)}></TextField>
                </div>
                <div className="flex-col">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label="Start"
                            minDate={moment().subtract(6,'years')}
                            maxDate={endDate}
                            value={startDate}
                            onChange={(newStartDate) => {setStartDate(newStartDate);}}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </div>
                <div className="flex-col">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label="End"
                            value={endDate}
                            onChange={(newEndDate) => { setEndDate(newEndDate);}}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </div>
            </div>
        </Paper>
        <Paper elevation={1}>
            <div style={{ height: 600, width: '100%' }}>
                <DataGridPro
                    rows={assessments}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[100]}
                    disableSelectionOnClick={true}
                    onFilterModelChange={(model) => updateCharts(model)}
                />
            </div>
        </Paper>

        <div className="chart">
            { timelineChartData ? 
                <Paper elevation={1}>
                <div className="line-chart">
                    <h2>Timeline</h2>
                    <LineChart
                        xAxis={[{ scaleType: 'point', data: timelineChartData.xAxis }]}
                        series={[
                            {
                                data: timelineChartData.data,
                            },
                        ]}
                    
                        height={500}
                    />
                </div>
                </Paper>
            :null }
        </div>

        <div className="chart pie-chart-wrapper">
            { pieChartData.length ? 
                <Paper elevation={1}>
                    <div className="pie-chart">
                        <h2>Classification</h2>
                        <PieChart
                            
                            series={[
                            {
                                data: pieChartData,
                            },
                            ]}
                            width={400}
                            height={300}
                            slotProps={ {legend: { hidden: true }} }
                        />
                    </div>
                </Paper>
            : null }
        </div>

        <div className="chart pie-chart-wrapper">
            { pieTypeChartData.length ? 
                <Paper elevation={1}>
                    <div className="pie-chart">
                        <h2>Types</h2>
                        <PieChart
                            series={[
                                {
                                data: pieTypeChartData,
                                },
                            ]}
                            width={400}
                            height={300}
                            slotProps={ {legend: { hidden: true }} }
                            />
                    </div>
                </Paper>
            : null }
        </div>      
        <div className="chart pie-chart-wrapper">
            { pieAgeChartData.length ? 
                <Paper elevation={1}>
                    <div className="pie-chart">
                        <h2>Age of Subject of Concern</h2>
                        <PieChart
                            series={[
                                {
                                data: pieAgeChartData,
                                },
                            ]}
                            width={400}
                            height={300}
                            slotProps={ {legend: { hidden: true }} }
                            />
                    </div>
                </Paper>
            : null }
        </div>      
        <div className="chart pie-chart-wrapper">
            { pieGenderChartData.length ? 
                <Paper elevation={1}>
                    <div className="pie-chart">
                        <h2>Gender of Subject of Concern</h2>
                        <PieChart
                            series={[
                                {
                                data: pieGenderChartData,
                                },
                            ]}
                            width={400}
                            height={300}
                            slotProps={ {legend: { hidden: true }} }
                            />
                    </div>
                </Paper>
            : null }
        </div>        
    </div>

    
  );
}

